import sysApi from "../assets/js/sysApi";


const getSysConf = (key = '') => {
	return new Promise( (resolve, reject) => {
		sysApi.getSysConf( _r => {
			_r?.data?.records?.forEach( _c => {
				if(key === '') {
					localStorage.setItem( _c.confKey, _c.confValue );
				} else {
					if( _c.confKey == key)localStorage.setItem( key, _c.confValue );
				}
			});
			resolve( _r );
		} )
	})
}


const actList   = [ getSysConf() ];

export default {
    load : function( callback ){
        Promise.all( actList ).then( _data => {
            callback( JSON.parse( localStorage.getItem( "WEB_CONF" ) ) );
        } )
    },
	
	reload : function ( key = '', callback = '' ) {
		Promise.all( [ getSysConf( key ) ] ).then( _data => {
			if(callback)callback();
		} )
		
	}
}
