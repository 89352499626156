if (!window.location.origin) {
    window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}
//软件开发环境   dev：本地开发环境 prod：生产环境
let active  = process.env.NODE_ENV == 'production' ? 'prod' : 'dev' ;


import conf from "./config.json";               //TCE测试环境配置文件
import prod from "./prod.json";                 //TCE生产环境配置文件
import testTencent from "./test-tencent.json";  //腾讯云测试环境配置文件
import prodTencent from "./prod-tencent.json"   //腾讯云生产环境配置文件
console.log( process.env.VUE_APP_RUNTIME, active );
let _config;

/*读取生产环境配置*/
if( active == 'prod' ){
    if( process.env.VUE_APP_RUNTIME == 'tencent' )
        _config     = Object.assign( conf, prodTencent );
    else
        _config     = Object.assign( conf, prod );
}else{
    if( process.env.VUE_APP_RUNTIME == 'tencent' )
        _config     = testTencent;
    else
        _config     = conf;
}
const globalConf    = Object.assign( _config, process.env.appConf );
globalConf.active   = active;
window.config       = eval( globalConf );
