import apiAxios from "@/config/apiAxios";

export default {
    getSysConf( response ){
        apiAxios.get( `${config.serverUrl}/cmss/v1/comp-service/config/list`, {}, response );
    },

    getChangeLog( response ){
        apiAxios.get( `${config.serverUrl}/cmss/v1/comp-service/netdisk/public/file_download/apps/cmdp/sys_update.json`, {}, response )
    },

    getTagConf( response ){
        apiAxios.agentGet( `${config.serverUrl}/basiceg/v1/json/product03-1255000087/pbc_fb/tags_config.json`, {}, _r => {
            let _rst    = [];
            if( _r.length > 0 ){
                _r.forEach( _tg => {
                    // 过滤某些条件
                    // if(_tg.tag == 'input_format') return; // 输入文件格式
                    if( _tg.items.length > 0 ){
                        let _conf = {
                            "key"     : _tg.tag,
                            "name"    : _tg.visual.find( _v => _v.type == "short" ).label,
                            "multiple": _tg.multiple,
                            "items"   : [],
                        };
                        _tg.items.forEach( _ti => {
                            _conf.items.push( {
                                "dtype"     : "str",
                                "type"      : "str",
                                "label"     : _ti.visual.find( _v => _v.type == "short" ).label,
                                "name"      : _ti.tag,
                                "operate"   : {
                                    "c": `${_tg.tag}_${_ti.tag}`,
                                    "e": "tag",
                                    "o": "eq"
                                },
                                "selected"  : false,
                            } );
                        } )
                        _rst.push( _conf )
                    }
                } )
            }
            response( _rst );
        } )
    }
}
