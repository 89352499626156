import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin         : false,  //用户登录状态
    infoChange      : false,  //用户信息变更通知
    favAppList      : false,  //用户APP快捷方式
    siteTabs        : {},
    siteTabsActvie  : false,
	taskMessages	: [], // 任务消息
	userInfoData	: {}, // 用户信息
  },
  getters : {
    isLogin         : state => state.isLogin,
    infoChange      : state => state.infoChange,
    favAppList      : state => state.favAppList,
    siteTabs        : state => state.siteTabs,
    siteTabsActvie  : state => state.siteTabsActvie,
	taskMessages	: state => state.taskMessages,
	userInfoData	: state => state.userInfoData,
  },
  mutations: {
    /*更新登录状态*/
    SET_LOGIN       : ( state, isLogin ) => {
      state.isLogin = isLogin === true;
    },
    /*用户信息变更*/
    SET_INFOCHANGE  : ( state ) => {
      state.infoChange = new Date().getTime();
    },
    /*收藏APP变更*/
    SET_FAVAPPLIST  : ( state, favAppList ) => {
      state.favAppList = favAppList;
    },
    /*添加应用标签*/
    SET_SITETABS    : ( state, tagConf ) => {
      let _siteTabs         = JSON.parse( JSON.stringify( state.siteTabs ) );
      if( !tagConf?.id || !tagConf?.name )
        return ;
      _siteTabs[tagConf.id] = tagConf;
      state.siteTabs        = _siteTabs;
      localStorage.setItem( "SITE_TABS", JSON.stringify( _siteTabs ) );
    },
    /*删除应用标签*/
    DEL_SITETABS    : ( state, tagConf ) => {
      let _siteTabs = JSON.parse( JSON.stringify( state.siteTabs ) );
      let _actId    = false;
      if( typeof tagConf == "object" ){
        _actId  = tagConf.id
      }else{
        _actId  = tagConf;
	  }
	  
	  // 记录关闭的tab栏的位置
	  let _curTabIndex = 0
	  Object.keys(_siteTabs).forEach((item, index) => {
		  if(item == _actId){
			 _curTabIndex = index
		  }
	  })
	  
      delete _siteTabs[_actId];
      state.siteTabs          = _siteTabs;
      localStorage.setItem( "SITE_TABS", JSON.stringify( _siteTabs ) );
	  
      if( _actId == state.siteTabsActvie ){
		  // 关闭自己时切换到最右侧的tab
		  let _tabsArr = Object.keys(_siteTabs)
		  // 选择关闭的tab右侧tab：1.关闭中间tab，顺次显示；2.关闭最右侧，再显示最右侧
		  let _actIndex = _curTabIndex > _tabsArr.length - 1 ? _tabsArr.length - 1 : _curTabIndex;
		  let _lastTabKey = _tabsArr[_actIndex]
        state.siteTabsActvie  = _lastTabKey ?? 'home';
	  }
    },
    /*设置活动标签*/
    SET_SITETABACT  : ( state, actId ) => {
      if( state.siteTabs[actId] )
        state.siteTabsActvie  = actId;
      localStorage.setItem( "SITE_ACTTAB", actId );
    },
    /*重置活动标签*/
    RESET_SITETABS    : ( state, tagConfs ) => {
      let _tabs       = {};
      Object.keys( tagConfs ).forEach( _k => {
        let _tag  = tagConfs[_k];
        if( _tag?.id && _tag?.name )
          _tabs[_k]   = _tag;
      } )
      state.siteTabs  = _tabs;
      localStorage.setItem( "SITE_TABS", JSON.stringify( state.siteTabs ) );
    },
	
	// 新建任务消息
	CREATE_TASK: ( state, task ) => {
		if(!task.taskId)task.taskId = task.path
		if(state.taskMessages.length == 0){
			state.taskMessages.push(task)
			return;
		}
		let _index = state.taskMessages.findIndex(item => {
			if(item.taskId == task.taskId && item.type == item.type) return true;
		})
		if(_index == -1) {
			state.taskMessages.push(task)
		} else {
			state.taskMessages.splice(_index, 1)
			state.taskMessages.push(task)
		}
		
	},
	// 修改任务消息
	CHANGE_TASK: ( state, task ) => {
		if(!task.taskId)task.taskId = task.path
		state.taskMessages.forEach( (item, index) => {
			if( item.taskId == task.taskId ) {
				state.taskMessages.splice(index, 1, task)
				if( 1 || task.status == 'success') {
					setTimeout(() => {
						state.taskMessages.splice(index, 1)
					},2000)
				}
			}
		})
	},
	
	// 关闭任务消息
	CLOSE_TASK: ( state, task ) => {
		if(!task.taskId)task.taskId = task.path
		state.taskMessages.forEach( (item, index) => {
			if( item.taskId == task.taskId ) {
				state.taskMessages.splice(index, 1)
			}
		})
	},
	
	// 存储用户信息
	SAVE_USERINFODATA: ( state, info ) => {
		state.userInfoData = info
	}
	
  },
  actions: {
    setLogin( { commit }, isLogin ){
      commit('SET_LOGIN', isLogin);
    },
    infoChange( { commit } ){
      commit( 'SET_INFOCHANGE', new Date().getTime() );
    },
    setFavAppList( { commit }, favAppList ){
      commit( 'SET_FAVAPPLIST',favAppList );
    },
    setSiteTabs( { commit }, tagConf ){

      commit( 'SET_SITETABS', tagConf );
      if( tagConf?.redir == false){

      }else
        commit( 'SET_SITETABACT', tagConf.id );

    },
    resetSiteTabs( { commit }, tagConfs ){
      commit( 'RESET_SITETABS', tagConfs );
    },
    delSiteTabs( { commit }, tagConf ){
      commit( 'DEL_SITETABS', tagConf );
    },
    setSiteTabAct( { commit }, actId ){
      commit( "SET_SITETABACT", actId );
    },
	
	createTaskMessage( {commit}, task )  {
		commit( "CREATE_TASK", task );
	},
	
	changeTaskMessage( {commit}, task ) {
		commit( "CHANGE_TASK", task );
	},
	
	closeTaskMessage( {commit}, task ) {
		commit( "CLOSE_TASK", task );
	},
	
	saveUserInfoData( {commit}, info ) {
		commit( "SAVE_USERINFODATA", info)
	}
	
	
  },
  modules: {
  }
})
