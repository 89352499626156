import moment from "moment"
moment.defineLocale('zh-cn', {
    relativeTime: {
        future: '%s内',
        past: '%s前',
        s: '几秒',
        m: '1分钟',
        mm: '%d分钟',
        h: '1小时',
        hh: '%d小时',
        d: '1天',
        dd: '%d天',
        M: '1个月',
        MM: '%d个月',
        y: '1年',
        yy: '%d年'
    },
});
/**
 * @fileOverview 监管前端脚手架通用功能封装
 * @author 郭猛
 * @module $jgGlobalFuc
 */
export default {
    /**
     * 生成UUID
     * @return {string}
     */
    getUUID_resource() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "";
        var uuid = s.join("");
        return uuid;
    },

    /**
     * 字符串左侧补零
     * @param str
     * @return {string}
     *
     * @example padLeftZero( 'abc', 3 ); //'abc'
     * padLeftZero( 'abc', 3 ); //'abc'
     * padLeftZero( 'abc', 4 ); //'0abc'
     */
    padLeftZero(str, length = 2) {
        return ( new Array( length ).fill( '0' ).join('') + str).substr(str.length);
    },
    /**
     * 标准时间格式化为字符串
     * @param {date} date Date型数据
     * @param {string} fmt 格式化类型
     */
    formatDate(date, fmt) {
        let _method = fmt.match( /\{.+\}?/g );
        let _moment = moment( date )
        if( _method ){
            let _methodConf = _method[0].slice( 1, -1 ).split( ':' );
            _moment.add( _methodConf[1], _methodConf[0] );
        }
        return _moment.format( fmt.replace( /\{.+\}?/g, '' ) );
    },
    /**
     * 获取当前时间
     * @param {boolean} needSecond 是否需要返回时分秒，默认不需要
     */
    getToday(needSecond) {
        return this.formatDate( new Date(), `YYYY-MM-DD${needSecond?' HH:mm:ss':''}` );
    },
    /**
     * 获取明天的日期
     * @param {boolean} needSecond 是否需要返回时分秒，默认不需要
     */
    getNextDate(needSecond) {
        return moment( new Date() ).add( 1, 'd' ).format( `YYYY-MM-DD${needSecond?' HH:mm:ss':''}` );
    },
    /**
     * 获取当前系统时间前多少天
     * @param {string} datt 日期 "2000-01-01"
     * @param {number} value 前几天
     */
    getBeforeDate(datt, value, needSecond = false ) {
        return moment( new Date( datt ) ).add( value * -1, 'd' ).format( `YYYY-MM-DD${needSecond?' HH:mm:ss':''}` );
    },
    /**
     * 根据出生日期计算年龄
     * @param {string} birthday "2000-01-01"
     */
    getAgeByBirth(birthday) {
        return moment( new Date( birthday ) ).diff( moment( new Date() ), 'y' ) * -1;
    },
    /**
     * 获取日历时间
     * @param date
     */
    getCalendarTime( date ){
        return moment( new Date( date ) ).fromNow();
    },
    /**
     * 生成错误返回值对象
     * @param code
     * @param msg
     */
    errResult( code, msg ){
        return { status:false, code:code, context:msg };
    },
    /**
     * 格式化文件大小信息
     * @param size
     */
    formatFileSize( size ){
        return size == 0 ? "-" : ( size > 1024 * 1024 ? `${(size / 1024 / 1024).toFixed(2)}MB` : `${(size / 1024).toFixed(2)}KB` );
    },
    
    /**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
    listToTree(data, id, parentId, children) {
        let config = {
            id: id || 'id',
            parentId: parentId || 'parentId',
            childrenList: children || 'children'
          };
        
          var childrenListMap = {};
          var nodeIds = {};
          var tree = [];
        
          for (let d of data) {
            let parentId = d[config.parentId];
            if (childrenListMap[parentId] == null) {
              childrenListMap[parentId] = [];
            }
            nodeIds[d[config.id]] = d;
            childrenListMap[parentId].push(d);
          }
        
          for (let d of data) {
            let parentId = d[config.parentId];
            if (nodeIds[parentId] == null) {
              tree.push(d);
            }
          }
        
          for (let t of tree) {
            adaptToChildrenList(t);
          }
        
          function adaptToChildrenList(o) {
            if (childrenListMap[o[config.id]] !== null) {
              o[config.childrenList] = childrenListMap[o[config.id]];
            }
            if (o[config.childrenList]) {
              for (let c of o[config.childrenList]) {
                adaptToChildrenList(c);
              }
            }
          }
          return tree;
    },
	
	
	/**
	 * 修改fileInfo中的path
	 * md=>user/documents/my document
	 */
	repSpaceIdToPath( _fileInfo ) {
		const replacePath = ( path ) => {
			if(path.startsWith('md')){
				path = path.replace( 'md', 'user/documents/my document');
			} else if(path.startsWith('mp')) {
				path = path.replace( 'mp', 'user/documents/my project');
			}
			return path
		}
		
		// 深拷贝
		let _f = JSON.parse(JSON.stringify(_fileInfo))
		// 判断数据类型
		if( Array.isArray(_f) ) {
			// 数组
			_f.forEach( (item,key) => {
				_f[key].fullName = replacePath(item.fullName)
				_f[key].extends.resource.path = replacePath(item.extends.resource.path);
			})
		} else {
			// 对象
			_f.fullName = replacePath(_f.fullName);
			_f.extends.resource.path = replacePath(_f.extends.resource.path);
			// _f.extends.resource.fileContentUrl = replacePath(_f.extends.resource.fileContentUrl); // 这个字段已修改成全路径
		}
		return _f;
	},
	
	/**
	 * 修改path中路径到spaceId
	 * user/documents/my document => md
	 */
	repPathToSpaceId(path) {
		let _path = path
		_path = _path.replace('user/documents/my document', 'md');
		_path = _path.replace('user/documents/my project', 'mp');
		return _path;
	},

  /**
 * Remove the extension from a file name if present.
 * @param {string} filename
 * @param {string} extension   For example ".json"
 * @return {string}
 */
 removeFileExtension (filename, extension) {
  if (filename.toLowerCase().endsWith(extension.toLowerCase())) {
    return filename.substring(0, filename.length - extension.length)
  }

  return filename
},

	/**
	 * 获取过去的时间
	 * @param {Object} date
	 * 几秒前（或者刚刚）
	 * 几分钟前
	 * 今天  hh:mm:ss  （当日）
	 * 昨天  hh:mm:ss （昨日）
	 * mm-dd hh:mm:ss （当年）
	 * yyyy-mm-dd（ 跨年）
	 */
	getPastTime(date) {
		let eYear = (new Date(date)).getFullYear();
		let eMonth = (new Date(date)).getMonth()
		let eDay = (new Date(date)).getDate()
		let eHour = (new Date(date)).getHours()
		
		let tYear = (new Date()).getFullYear();
		let tMonth = (new Date()).getMonth()
		let tDay = (new Date()).getDate()
		let tHour = (new Date()).getHours()
		
		if( tYear != eYear ) {
			// 跨年 
			return `${this.formatDate( date, 'YY年MM月DD日 HH:mm' )}`
		} else {
			// 当年
			
			if( eMonth != tMonth ) {
				// 跨月
				return `${this.formatDate( date, 'MM月DD日 HH:mm:ss' )}`
			} else {
				//当月
				if( eDay != tDay ) {
					if(tDay - eDay > 1) return `${this.formatDate( date, 'MM月DD日 HH:mm:ss' )}` 
					if(tDay - eDay == 1) return `昨天 ${this.formatDate( date, 'HH:mm:ss' )}`
				} else {
					// 今天
					if(tHour -  eHour > 4) {
						return `今天 ${this.formatDate( date, 'HH:mm:ss' )}`
					} else {
						return moment( new Date( date ) ).fromNow();
					} 
					
				}
			}
		}
	}
	
	
}
