<template>
  <el-main class="main-box">
    <h1>404</h1>
    <h4>您访问的页面不存在</h4>
    <div>
      <router-link to="/index">点我返回首页</router-link>
    </div>
  </el-main>
</template>

<script>
export default {
  name: "P404",
  created() {
    this.$EventBus.$emit( "set-sub-title", [ { name : "数字化平台", link : "/" } ] );
  },
  activated() {
    this.$EventBus.$emit( "set-sub-title", [ { name : "数字化平台", link : "/" } ] );
  }
}
</script>

<style lang="less" scoped>
.main-box{
  background:#4f8dc3;
  width:100vw;
  height:100vh;
  clear: both;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > *{
    text-align: center;
    color:#ffffff;
    margin-bottom:20px;
    *{
      color:#ffffff;
    }
  }
  h1{
    margin-top:-80px;
    margin-bottom:20px;
    font-size:110px;
  }
  .el-link{
    height:20px;
    line-height: 20px;
    &:hover{
      color:#ffffff;
      font-size:15px;
    }
  }
}
</style>
