<template>
  <div>
    <router-view ref="opt-window" />
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {},
  mounted() {
    if (window.EventSource){
    var eventkey = $func.getUUID_resource();
    window.eventkey = eventkey;
    let that=this;
 //   let eventurl="http://172.100.30.251:8080/msg/v1/msg/sse"
    let eventurl = `${config.serverUrl}/msg/v1/msg/sse?uid=${eventkey}`
    var evt_source = new EventSource(eventurl);
    window.evt_source = evt_source;
    window.evt_source.onmessage = evt => {
      if (evt.data) {
        let data = JSON.parse(evt.data)
        if (data.sseKey) {
          window.sseKey = data.sseKey;
        }
      }

      console.log(evt);
    };
    window.evt_source.onopen = e => {
      console.log(e);
    }
    // 链接报错
    window.evt_source.onerror = err => {
      if (err.type == 'error') {

      }
      // console.log(err);
    }
    window.evt_source.addEventListener('runPbc',(e)=>{
           that.$EventBus.$emit("runPbc",e.data)
          //  console.log(e);
        })
    // 浏览器关闭，关闭sse
    window.onbeforeunload = function () {
      window.evt_source.close();
    }
  }else{
    console.error("浏览器不支持sse")
  }
  }
}
</script>

<style scoped>

</style>
