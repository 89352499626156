/**
 * VUE混入及指令
 */
import Vue from "vue";
const siteSkin  = {
    data(){
        return{
            isWx        : false,    //是否微信端
            // isMobile    : false,    //是否移动端
            sysTag      : "web",    //用户系统 web|app
            viewSkin    : "",       //主题 默认def
            SYS_CONF    : {},       //配置信息
            pgLading    : true,     //页面加载中标记位

			baseMenu	: {},
			allowUser	:  [
				'18010475166',
				'15522331518', // 猛哥
				'13702053133', // 杨总
				'17695427525', // 徐聪
				'13920973290', // 韩旭
				'13652077553', // 杜文斌
			],
			isValidTag: window.config.active == "prod", // 是否开启标签验证， 生产开启，测试关闭
			matchTagVista: 'prod_数字化云盘_用户状态_通过', // 验证标签
			
			
        }
    },
	watch: {
		// "$store.getters.userInfoData.telphone": {
		// 	handler( newVal, oldVal ){
		// 		if(newVal != oldVal) {
		// 			console.log(newVal, oldVal)
		// 			this.initBaseMenu()
		// 		}
		// 	},
		// 	deep: true,
		// 	immediate: true
		// },
	},
    computed    : {
        isLogin(){
            return this.$store.state.isLogin;
        },
		userInfoData() {
			return this.$store.state.userInfoData
		}
    },
    methods     : {
        setSiteTabs( tagConf, redir = true ){
            tagConf.redir   = redir;
            this.$store.dispatch( "setSiteTabs", tagConf );
        },
        delSiteTabs( tagConf ){
            this.$store.dispatch( "delSiteTabs", tagConf );
        },

		// 功能正在建设中
		inBuilding(val) {
			this.$message( '功能建设中' )
		},

		// 重载tab标签页，设置当前加载
		reloadTab(key) {
			this.setSiteTabs( {
			  "id"    : this.baseMenu[key]?._id,
			  "name"  : this.baseMenu[key]?.title,
			  "logo"  : this.baseMenu[key]?.logo,
			  "route" : this.$route.path,
			} );
		},

		// 设置页面副标题（左上角显示 文档/项目/日历等）
		setSubTitle(key) {
			this.$EventBus.$emit( "set-sub-title", [{
				name : this.baseMenu[key]?.title ,
				link : this.baseMenu[key]?.route ,
			}] );
		},

        onPgReload(){
            return false;
        },

		initBaseMenu() {
			this.baseMenu = {
			    'home' 		: { _id   : "home",      title : "首页",   logo  : `/img/${this.viewSkin}/common/home.png` } ,
				'userDoc' 	: { _id   : "userDoc",   title : "文档",   link : '/user/docs', logo  : `/img/${this.viewSkin}/common/document.svg` } ,
				'userPrj' 	: { _id   : "userPrj",   title : "项目",   link : '/user/project', logo  : `/img/${this.viewSkin}/common/project.svg` } ,
				'userDocSys': { _id   : "userDocSys",title : "系统",   link : '/user/docSys', logo  : `/img/${this.viewSkin}/common/system.svg` } ,
				'calendar' 	: { _id   : "calendar",  title : "日历",   link : '/app/calendar', logo  : `/img/${this.viewSkin}/common/calendar.svg` } ,

				// 'resource1': { _id   : "resource1",   title : "文档V2",   link : '/user/resource/doc', logo  : `/img/${this.viewSkin}/common/document.svg` },
				// 'resource2': { _id   : "resource2",   title : "项目V2",   link : '/user/resource/prj', logo  : `/img/${this.viewSkin}/common/project.svg` }

			};
			// 针对某些人显示
			if(1 || this.allowUser.includes(this.userInfoData?.telphone) ){
				this.$set(this.baseMenu, 'resource1', { _id   : "resource1",   title : "文档V2",   link : '/user/resource/doc', logo  : `/img/${this.viewSkin}/common/document.svg` })
				this.$set(this.baseMenu, 'resource2', { _id   : "resource2",   title : "项目V2",   link : '/user/resource/prj', logo  : `/img/${this.viewSkin}/common/project.svg` })

			}

		},

    },
    created() {

        this.viewSkin   = sessionStorage.getItem( "view-skin" ) || "def";
        this.SYS_CONF   = window.config;
        this.isWx       = sessionStorage.getItem( "isWx" ) == "true" || false;
        this.isMobile   = sessionStorage.getItem( "isMobile" ) == "true" || false;
        this.sysTag     = sessionStorage.getItem( "USER_SYSTEM" ) == "app" ? "app" : "web";
        let _login      = sessionStorage.getItem( "USER_ISLOGIN" ) == "true" || false;
        this.$store.dispatch( "setLogin", _login );

		const _userInfo = JSON.parse( sessionStorage.getItem( "USER_INFO" ) );
		if(_userInfo?.telphone) this.$store.dispatch( "saveUserInfoData", _userInfo );

		this.initBaseMenu()

    }
}
Vue.mixin( siteSkin );

/**
 * 新增标签页指令定义
 */
Vue.directive( 'tabConf', {
    bind : ( el, binding, vnode ) => {
        if( !binding.value?.id )
            return ;
        let _tagConf    = {
            "id"    : binding.value?.id,
            "name"  : binding.value?.title,
            "logo"  : binding.value?.logo,
            "icon"  : binding.value?.icon,
            "route" : binding.value?.path,
            "conf"  : binding.value?.conf
        }
        el.onclick      = () => {
            if( _tagConf.route && _tagConf.route.startsWith( "http" ) )
                return ;
            _tagConf.route   = _tagConf.route || window.Vue.$route.path;
            window.Vue.setSiteTabs( _tagConf );
            // window.Vue.$EventBus.$emit( "addAppTag", _tagConf.id, _tagConf );
        }
    }
} )

/**
 * 防止按钮重复点击
 */
Vue.directive( 'preventReClick', {
    inserted: (el, binding, vNode, oldVnode) => {
        el.addEventListener( 'click', () => {
            if(!el.disabled) {
                el.disabled = true;
                setTimeout(() => {
                    el.disabled = false;
                }, 800)
            }
        })
    }
})
